import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div className="lab3-container content" style={{ marginTop: '165px' }}>
      <div className="columns">
        <div
          className="column is-12 has-text-centered"
          style={{ margin: '8em 0', color: 'white' }}
        >
          <h1 style={{ color: 'white' }}>Not Found</h1>
          <p>The page you tried to access does not exist</p>
          <Link to="/" title="Go Home">
            <div
              style={{
                cursor: 'pointer',
                color: '#43c5e4',
              }}
            >
              Go home
            </div>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
